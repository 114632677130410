import React from 'react';

import '../sass/searchbar.scss';

class SearchBar extends React.Component {

    render() {

      return (
        <input className="searchbar" onChange={this.props.onSearch} type="text" placeholder={`Buscar en ${this.props.filtroSeleccionado}`} name="searchbar"></input>
      );
    }
  }

  export default SearchBar;