import React from "react";
import firebase from "firebase/app";
import 'firebase/firestore' ;

export const useGetData = () => {
  const [documents, setDocuments] = React.useState([]);
  const db = firebase.firestore();
  React.useEffect(() => {
    db.collection("Solicitudes").where("estado", "!=", 'cliente')
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, data: doc.data() })
        );
        setDocuments(arr);
      });
  }, [db]);
  return [documents];
};