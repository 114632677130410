import React from 'react';

import NavBar from './navbar';

class Layout extends React.Component{
    render(){   
        return(
            <React.Fragment>
                <NavBar 
                    pagina={this.props.pagina}
                    onPageLoad= {this.props.onPageLoad}
                    accion="Salir" 
                    urlAccion="/login"/>
                {this.props.children}
            </React.Fragment>
    
        )
    }
}

export default Layout;