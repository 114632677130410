import React from 'react';

import SearchBar from '../components/searchbar';
import Dropdown from '../components/dropdown';
import Inputfield from '../components/inputfield';
import BtnImg from '../components/btnimg';
import MultipleBtn from '../components/multiplebtn';
import ModalImgINE from '../components/modalImgINE';


//import ine from "../resources/INE.jpeg";
import ampliarIcon from "../resources/ampliar-icon.svg";
import accionesIcon from "../resources/acciones-btn.svg";
import aprobarIcon from "../resources/aprobar-btn.svg";

class LayoutPage extends React.Component{

    constructor() {
        super();
        this.state = {
          show: false,
        };
        this.AbrirImgINE = this.AbrirImgINE.bind(this);
        this.CerrarImgINE = this.CerrarImgINE.bind(this);
      }
    
      AbrirImgINE = () => {
        
        this.setState({ show: true });

        /*
            <ModalImgINE show={this.state.show} handleClose={this.CerrarImgINE}>
                <img className="img-modal" src={this.props.cliente.ine_img} alt="Imagen INE" />
            </ModalImgINE>
        */
      };
    
      CerrarImgINE = () => {
        this.setState({ show: false });
      };

    ObtenerNombreCompleto(cliente){
        return cliente.nombres + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
    }

    ObtenerInformacionAval(){

    }

    render(){
        var opciones={};
        if(this.props.pagina.actual==='Solicitudes Personales'){
            opciones= ["Todos", "Aprobado", "Rechazado", "Lista Negra"];
        }else{
            opciones = ["Grupos", "Completo", "Incompleto", "Clientes"];
        }

        var accionesBtn = ["Editar","Condicionar a aval","Rechazar", "Visitar prospecto", "Lista Negra","WhatsApp"];
    
        return (
            <React.Fragment>
                    <ModalImgINE show={this.state.show} handleClose={this.CerrarImgINE}>
                         <img className="img-modal" src={this.props.aval? this.props.cliente.aval.ine_url :this.props.cliente.ine_url} alt="Imagen INE" />
                    </ModalImgINE>
                    <div className="page-container">
                    <div className="top-header">
                        <h1 className="titulo">Validar buró de crédito</h1>
                        <div className="top-header-content">
                            <SearchBar onSearch={this.props.onSearch} filtroSeleccionado={this.props.filtro} />
                            <Dropdown opciones={opciones} filtroSeleccionado={this.props.filtro} setFiltro={this.props.setFiltro}/>
                        </div>
                    </div>
                    <div className="cards">
                        <div className="cards-container">
                             {this.props.children}
                        </div>
                    </div>
                        <div className="solicitud-info">
                            <div className="solicitud-info-container">
                                <div className="ine-container">
                                <BtnImg onClick={this.AbrirImgINE} imgSrc={ampliarIcon} addedStyle="btn-fixed" />
                                <img src={this.props.aval? this.props.cliente.aval.ine_url :this.props.cliente.ine_url} alt="Imagen INE" />
                                </div>
                                <div className="label-container">
                                    <button onClick={() => this.props.onInformacionSeleccionado()} className={`informacion ${this.props.aval? "desactivado" : "activo"}`}>Información</button>
                                    {this.props.pagina.actual==="Solicitudes Personales" && this.props.contieneAval &&
                                         <button onClick={this.props.onAvalSeleccionado} className={`aval ${this.props.aval? "activo" : "desactivado"}`}>Aval</button>
                                    }
                                </div>
                                <div className="campos-container">
                                    <Inputfield campo="Nombre completo" valor={this.props.aval? this.ObtenerNombreCompleto(this.props.cliente.aval) : this.ObtenerNombreCompleto(this.props.cliente) }/>
                                    <Inputfield campo="Acitivad económica" valor={this.props.aval? this.props.cliente.aval.actividad_economica : this.props.cliente.actividad_economica}/>
                                    <Inputfield campo="Clave lector" valor={this.props.aval? this.props.cliente.aval.clave_lector : this.props.cliente.clave_lector}/>
                                    <Inputfield campo="Teléfono" valor={this.props.aval? this.props.cliente.aval.telefono : this.props.cliente.telefono}/>
                                </div>
                                <div className="btns-container">
                                <MultipleBtn imgSrc={accionesIcon} opciones={accionesBtn} objects={this.props.aval? this.props.cliente.aval.telefono : this.props.cliente.telefono}/>
                                <BtnImg imgSrc={aprobarIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
    
        );
    }
}

export default LayoutPage;