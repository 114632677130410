import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import Login from '../pages/login';
import SolicitudesPersonales from '../pages/solicitudesPersonales';
import SolicitudesGrupales from '../pages/solicitudesGrupales';
import DocumentacionPrestamoPersonal from '../pages/docPrestamoPersonal';

import '../sass/resets.scss';

import firebase from "firebase/app";
    firebase.initializeApp({
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
});


function App(){
    return (
    <BrowserRouter>

        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/SolicitudesPersonales" component={SolicitudesPersonales}/>
            <Route exact path="/SolicitudesGrupales" component={SolicitudesGrupales}/>
            <Route exact path="/Documentos/:id" component={DocumentacionPrestamoPersonal}/>
            <Route path="/404" component={Login} />
            <Redirect from="*" to="/404" />
        </Switch>

    </BrowserRouter>

    );
}

export default App;