import React from "react";

import '../sass/buttons.scss'

class BtnImg extends React.Component {
  
  render() {

    const {
      imgSrc,
      addedStyle,
        } = this.props;

        var generatedStyle = `btn-img ${addedStyle}`;

    return (
        <button onClick={this.props.onClick} className={generatedStyle} alt="Botón">
             <img src={imgSrc} alt="Botón con imagen" />
        </button>
    );
  }
}

export default BtnImg;
