import React from 'react';
import Layout from '../components/layout';
import LayoutPage from '../components/layoutPage';
import SolicitudGrupalList from '../components/solicitudGrupalList';

import '../sass/solicitudesPersonales.scss'


class SolicitudesGrupales extends React.Component{

    constructor(props) {
        super(props)
        this.handleClienteSeleccionado = this.handleClienteSeleccionado.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleFiltroSeleccionado = this.handleFiltroSeleccionado.bind(this)
        this.handlePaginaSeleccionada = this.handlePaginaSeleccionada.bind(this)
    }

    state = {
        dataInicial: [
            {
                id : "G1",
                nombre : "Grupo alfa",
                promotor: "Ahtziri",
                fecha : "23 de Marzo de 2021",
                estado: "",
                "integrantes": [  
                {
                    "id": "i1",
                    "nombre" : "Carlos Cobos",
                    "promotor": "Ahtziri",
                    "actividad_economica" : "Diseñador",
                    "antiguedad": "3 años",
                    "ine_img": "https://bn1304files.storage.live.com/y4mrSx_QI-yv9uWSFlmx3J578Bju3FKtFb9cg3jog64h7czD0Tx3iCvt9JvXEBIhTQ_W29w3WxtmSKOtsuzr9GDwyuXeWYOhVTILEha6lu11irK9jlwWq5K5Vt3-ybcN5vAklwV5ZDjiyOS98u-N3FbQvZM1lhiY_OjiSi5aMDD4xpeOqKF8keZAvFFDAkoXzhI?width=960&height=603&cropmode=none",
                    "clave_lector": "ASKMDSKDMF34D",
                    "telefono": "5549209235",
                    "estado": "aprobado",
                    "solicitud": {
                        "datos_personales":{
                            "nombres": "Carlos Andrés",
                            "apellido_paterno": "Cobos",
                            "apellido_materno": "Cobos",
                            "curp": "COCC941028HVZBBR06",
                            "fecha_nacimiento": "28/10/1994",
                            "edad": "26 años",
                            "genero": "Masculino",
                            "estado_nacimiento": "Veracruz",
                            "pais": "México",
                            "nacionalidad": "Mexicano",
                            "estado_civil": "Soltero",
                            "posicion_hogar": "Hijo",
                        },
                        "direccion":{
                            "calle": "Mirlos",
                            "num_exterior": "#59",
                            "num_interior": "N/A",
                            "colonia": "Las Gaviotas",
                            "municipio": "Poza Rica de Hidalgo",
                            "estado_domicilio": "Veracruz",
                            "codigo_postal": "93305",
                            "correo_electronico": "carlos.cobos941028@hotmail.com",
                            "celular": "5549209235",
                            "telefono_fijo": "7828243607",
                            "telefono_recados": "7821058019",
                            "persona_recado": "Perla",
                            "caracteristicas": "Casa Azul"
                        },
                        "conyuge":{
                            "nombres": "no tengo",
                            "apellido_paterno": "ninguno",
                            "apellido_materno": "ninguno",
                            "fecha_nacimiento": "01/01/01",
                            "celular": "7821234567",
                            "ocupacion": "ninguno",
                            "lugar_trabajo": "ninguno"
                        },
                        "referencias":{
                            "referencia 1":{
                                "nombres": "Perla",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cobos",
                                "parentesco": "Madre",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            },
                            "referencia 2":{
                                "nombres": "Refugia",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cruz",
                                "parentesco": "Abuela",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            }
                        },                    
                        "empresa":{
                            "nombre": "Ninguno",
                            "jefe": "Ninguno",
                            "puesto": "Ninguno",
                            "tiempo_laborando": "0 años",
                            "salario": "0",
                            "telefono": "N/A",
                            "direccion":{
                                "calle": "Mirlos",
                                "num_exterior": "#59",
                                "num_interior": "N/A",
                                "colonia": "Las Gaviotas",
                                "municipio": "Poza Rica de Hidalgo",
                                "estado_domicilio": "Veracruz",
                                "codigo_postal": "93305",
                                "telefono": "7828243607",
                            }
                        },
                        "prestamo":{
                            "fecha_solicitud" : "23 de Marzo de 2021", 
                            "clasificacion": "Préstamo Paralelo",
                            "plan": "16 Semanas",
                            "semanas": "16",
                            "monto":"$2,000",
                            "monto letra": "(Seis mil cuatrosientos pesos 00/100 M.N.)",
                            "cargo_servicio": "$1,800",
                            "total_pagar": "$4,800",
                            "cat": "3597.24%",
                            "primer_pago": "$300",
                            "pagos_subsecuentes": "$300",
                            "dia_pago": "Martes",
                            "interes_ordinario": "60%",
                            "interes_moratorio": "$30",
    
                        } 
                    },
                },
                {
                    "id": "i2",
                    "nombre" : "Andrés Anzures",
                    "promotor": "Ahtziri",
                    "actividad_economica" : "Diseñador",
                    "antiguedad": "3 años",
                    "clave_lector": "ASKMDSKDMF34D",
                    "telefono": "5549209235",
                    "estado": "aprobado",
                    "solicitud": {
                        "datos_personales":{
                            "nombres": "Carlos Andrés",
                            "apellido_paterno": "Cobos",
                            "apellido_materno": "Cobos",
                            "curp": "COCC941028HVZBBR06",
                            "fecha_nacimiento": "28/10/1994",
                            "edad": "26 años",
                            "genero": "Masculino",
                            "estado_nacimiento": "Veracruz",
                            "pais": "México",
                            "nacionalidad": "Mexicano",
                            "estado_civil": "Soltero",
                            "posicion_hogar": "Hijo",
                        },
                        "direccion":{
                            "calle": "Mirlos",
                            "num_exterior": "#59",
                            "num_interior": "N/A",
                            "colonia": "Las Gaviotas",
                            "municipio": "Poza Rica de Hidalgo",
                            "estado_domicilio": "Veracruz",
                            "codigo_postal": "93305",
                            "correo_electronico": "carlos.cobos941028@hotmail.com",
                            "celular": "5549209235",
                            "telefono_fijo": "7828243607",
                            "telefono_recados": "7821058019",
                            "persona_recado": "Perla",
                            "caracteristicas": "Casa Azul"
                        },
                        "conyuge":{
                            "nombres": "no tengo",
                            "apellido_paterno": "ninguno",
                            "apellido_materno": "ninguno",
                            "fecha_nacimiento": "01/01/01",
                            "celular": "7821234567",
                            "ocupacion": "ninguno",
                            "lugar_trabajo": "ninguno"
                        },
                        "referencias":{
                            "referencia 1":{
                                "nombres": "Perla",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cobos",
                                "parentesco": "Madre",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            },
                            "referencia 2":{
                                "nombres": "Refugia",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cruz",
                                "parentesco": "Abuela",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            }
                        },                    
                        "empresa":{
                            "nombre": "Ninguno",
                            "jefe": "Ninguno",
                            "puesto": "Ninguno",
                            "tiempo_laborando": "0 años",
                            "salario": "0",
                            "telefono": "N/A",
                            "direccion":{
                                "calle": "Mirlos",
                                "num_exterior": "#59",
                                "num_interior": "N/A",
                                "colonia": "Las Gaviotas",
                                "municipio": "Poza Rica de Hidalgo",
                                "estado_domicilio": "Veracruz",
                                "codigo_postal": "93305",
                                "telefono": "7828243607",
                            }
                        },
                        "prestamo":{
                            "fecha_solicitud" : "23 de Marzo de 2021", 
                            "clasificacion": "Préstamo Paralelo",
                            "plan": "16 Semanas",
                            "semanas": "16",
                            "monto":"$2,000",
                            "monto letra": "(Seis mil cuatrosientos pesos 00/100 M.N.)",
                            "cargo_servicio": "$1,800",
                            "total_pagar": "$4,800",
                            "cat": "3597.24%",
                            "primer_pago": "$300",
                            "pagos_subsecuentes": "$300",
                            "dia_pago": "Martes",
                            "interes_ordinario": "60%",
                            "interes_moratorio": "$30",
    
                        } 
                    },
                },
                {
                    "id": "i3",
                    "nombre" : "Christian Trejo",
                    "promotor": "Ahtziri",
                    "actividad_economica" : "Diseñador",
                    "antiguedad": "3 años",
                    "clave_lector": "ASKMDSKDMF34D",
                    "telefono": "5549209235",
                    "estado": "aprobado",
                    "solicitud": {
                        "datos_personales":{
                            "nombres": "Carlos Andrés",
                            "apellido_paterno": "Cobos",
                            "apellido_materno": "Cobos",
                            "curp": "COCC941028HVZBBR06",
                            "fecha_nacimiento": "28/10/1994",
                            "edad": "26 años",
                            "genero": "Masculino",
                            "estado_nacimiento": "Veracruz",
                            "pais": "México",
                            "nacionalidad": "Mexicano",
                            "estado_civil": "Soltero",
                            "posicion_hogar": "Hijo",
                        },
                        "direccion":{
                            "calle": "Mirlos",
                            "num_exterior": "#59",
                            "num_interior": "N/A",
                            "colonia": "Las Gaviotas",
                            "municipio": "Poza Rica de Hidalgo",
                            "estado_domicilio": "Veracruz",
                            "codigo_postal": "93305",
                            "correo_electronico": "carlos.cobos941028@hotmail.com",
                            "celular": "5549209235",
                            "telefono_fijo": "7828243607",
                            "telefono_recados": "7821058019",
                            "persona_recado": "Perla",
                            "caracteristicas": "Casa Azul"
                        },
                        "conyuge":{
                            "nombres": "no tengo",
                            "apellido_paterno": "ninguno",
                            "apellido_materno": "ninguno",
                            "fecha_nacimiento": "01/01/01",
                            "celular": "7821234567",
                            "ocupacion": "ninguno",
                            "lugar_trabajo": "ninguno"
                        },
                        "referencias":{
                            "referencia 1":{
                                "nombres": "Perla",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cobos",
                                "parentesco": "Madre",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            },
                            "referencia 2":{
                                "nombres": "Refugia",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cruz",
                                "parentesco": "Abuela",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            }
                        },                    
                        "empresa":{
                            "nombre": "Ninguno",
                            "jefe": "Ninguno",
                            "puesto": "Ninguno",
                            "tiempo_laborando": "0 años",
                            "salario": "0",
                            "telefono": "N/A",
                            "direccion":{
                                "calle": "Mirlos",
                                "num_exterior": "#59",
                                "num_interior": "N/A",
                                "colonia": "Las Gaviotas",
                                "municipio": "Poza Rica de Hidalgo",
                                "estado_domicilio": "Veracruz",
                                "codigo_postal": "93305",
                                "telefono": "7828243607",
                            }
                        },
                        "prestamo":{
                            "fecha_solicitud" : "23 de Marzo de 2021", 
                            "clasificacion": "Préstamo Paralelo",
                            "plan": "16 Semanas",
                            "semanas": "16",
                            "monto":"$2,000",
                            "monto letra": "(Seis mil cuatrosientos pesos 00/100 M.N.)",
                            "cargo_servicio": "$1,800",
                            "total_pagar": "$4,800",
                            "cat": "3597.24%",
                            "primer_pago": "$300",
                            "pagos_subsecuentes": "$300",
                            "dia_pago": "Martes",
                            "interes_ordinario": "60%",
                            "interes_moratorio": "$30",
    
                        } 
                    },
                },
                {
                    "id": "i4",
                    "nombre" : "Carlos Cobos",
                    "promotor": "Ahtziri",
                    "actividad_economica" : "Diseñador",
                    "antiguedad": "3 años",
                    "clave_lector": "ASKMDSKDMF34D",
                    "telefono": "5549209235",
                    "estado": "aprobado",
                    "solicitud": {
                        "datos_personales":{
                            "nombres": "Carlos Andrés",
                            "apellido_paterno": "Cobos",
                            "apellido_materno": "Cobos",
                            "curp": "COCC941028HVZBBR06",
                            "fecha_nacimiento": "28/10/1994",
                            "edad": "26 años",
                            "genero": "Masculino",
                            "estado_nacimiento": "Veracruz",
                            "pais": "México",
                            "nacionalidad": "Mexicano",
                            "estado_civil": "Soltero",
                            "posicion_hogar": "Hijo",
                        },
                        "direccion":{
                            "calle": "Mirlos",
                            "num_exterior": "#59",
                            "num_interior": "N/A",
                            "colonia": "Las Gaviotas",
                            "municipio": "Poza Rica de Hidalgo",
                            "estado_domicilio": "Veracruz",
                            "codigo_postal": "93305",
                            "correo_electronico": "carlos.cobos941028@hotmail.com",
                            "celular": "5549209235",
                            "telefono_fijo": "7828243607",
                            "telefono_recados": "7821058019",
                            "persona_recado": "Perla",
                            "caracteristicas": "Casa Azul"
                        },
                        "conyuge":{
                            "nombres": "no tengo",
                            "apellido_paterno": "ninguno",
                            "apellido_materno": "ninguno",
                            "fecha_nacimiento": "01/01/01",
                            "celular": "7821234567",
                            "ocupacion": "ninguno",
                            "lugar_trabajo": "ninguno"
                        },
                        "referencias":{
                            "referencia 1":{
                                "nombres": "Perla",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cobos",
                                "parentesco": "Madre",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            },
                            "referencia 2":{
                                "nombres": "Refugia",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cruz",
                                "parentesco": "Abuela",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            }
                        },                    
                        "empresa":{
                            "nombre": "Ninguno",
                            "jefe": "Ninguno",
                            "puesto": "Ninguno",
                            "tiempo_laborando": "0 años",
                            "salario": "0",
                            "telefono": "N/A",
                            "direccion":{
                                "calle": "Mirlos",
                                "num_exterior": "#59",
                                "num_interior": "N/A",
                                "colonia": "Las Gaviotas",
                                "municipio": "Poza Rica de Hidalgo",
                                "estado_domicilio": "Veracruz",
                                "codigo_postal": "93305",
                                "telefono": "7828243607",
                            }
                        },
                        "prestamo":{
                            "fecha_solicitud" : "23 de Marzo de 2021", 
                            "clasificacion": "Préstamo Paralelo",
                            "plan": "16 Semanas",
                            "semanas": "16",
                            "monto":"$2,000",
                            "monto letra": "(Seis mil cuatrosientos pesos 00/100 M.N.)",
                            "cargo_servicio": "$1,800",
                            "total_pagar": "$4,800",
                            "cat": "3597.24%",
                            "primer_pago": "$300",
                            "pagos_subsecuentes": "$300",
                            "dia_pago": "Martes",
                            "interes_ordinario": "60%",
                            "interes_moratorio": "$30",
    
                        } 
                    },
                },
                {
                    "id": "i5",
                    "nombre" : "Maria Jose Anzures",
                    "promotor": "Ahtziri",
                    "actividad_economica" : "Diseñador",
                    "antiguedad": "3 años",
                    "clave_lector": "ASKMDSKDMF34D",
                    "telefono": "5549209235",
                    "estado": "rechazado",
                    "solicitud": {
                        "datos_personales":{
                            "nombres": "Carlos Andrés",
                            "apellido_paterno": "Cobos",
                            "apellido_materno": "Cobos",
                            "curp": "COCC941028HVZBBR06",
                            "fecha_nacimiento": "28/10/1994",
                            "edad": "26 años",
                            "genero": "Masculino",
                            "estado_nacimiento": "Veracruz",
                            "pais": "México",
                            "nacionalidad": "Mexicano",
                            "estado_civil": "Soltero",
                            "posicion_hogar": "Hijo",
                        },
                        "direccion":{
                            "calle": "Mirlos",
                            "num_exterior": "#59",
                            "num_interior": "N/A",
                            "colonia": "Las Gaviotas",
                            "municipio": "Poza Rica de Hidalgo",
                            "estado_domicilio": "Veracruz",
                            "codigo_postal": "93305",
                            "correo_electronico": "carlos.cobos941028@hotmail.com",
                            "celular": "5549209235",
                            "telefono_fijo": "7828243607",
                            "telefono_recados": "7821058019",
                            "persona_recado": "Perla",
                            "caracteristicas": "Casa Azul"
                        },
                        "conyuge":{
                            "nombres": "no tengo",
                            "apellido_paterno": "ninguno",
                            "apellido_materno": "ninguno",
                            "fecha_nacimiento": "01/01/01",
                            "celular": "7821234567",
                            "ocupacion": "ninguno",
                            "lugar_trabajo": "ninguno"
                        },
                        "referencias":{
                            "referencia 1":{
                                "nombres": "Perla",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cobos",
                                "parentesco": "Madre",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            },
                            "referencia 2":{
                                "nombres": "Refugia",
                                "apellido_paterno": "Cobos",
                                "apellido_materno": "Cruz",
                                "parentesco": "Abuela",
                                "direccion":{
                                    "calle": "Mirlos",
                                    "num_exterior": "#59",
                                    "num_interior": "N/A",
                                    "colonia": "Las Gaviotas",
                                    "municipio": "Poza Rica de Hidalgo",
                                    "estado_domicilio": "Veracruz",
                                    "codigo_postal": "93305",
                                    "correo_electronico": "carlos.cobos941028@hotmail.com",
                                    "celular": "5549209235",
                                    "telefono_fijo": "7828243607",
                                    "telefono_recados": "7821058019",
                                    "persona_recado": "Perla",
                                }
                            }
                        },                    
                        "empresa":{
                            "nombre": "Ninguno",
                            "jefe": "Ninguno",
                            "puesto": "Ninguno",
                            "tiempo_laborando": "0 años",
                            "salario": "0",
                            "telefono": "N/A",
                            "direccion":{
                                "calle": "Mirlos",
                                "num_exterior": "#59",
                                "num_interior": "N/A",
                                "colonia": "Las Gaviotas",
                                "municipio": "Poza Rica de Hidalgo",
                                "estado_domicilio": "Veracruz",
                                "codigo_postal": "93305",
                                "telefono": "7828243607",
                            }
                        },
                        "prestamo":{
                            "fecha_solicitud" : "23 de Marzo de 2021", 
                            "clasificacion": "Préstamo Paralelo",
                            "plan": "16 Semanas",
                            "semanas": "16",
                            "monto":"$2,000",
                            "monto letra": "(Seis mil cuatrosientos pesos 00/100 M.N.)",
                            "cargo_servicio": "$1,800",
                            "total_pagar": "$4,800",
                            "cat": "3597.24%",
                            "primer_pago": "$300",
                            "pagos_subsecuentes": "$300",
                            "dia_pago": "Martes",
                            "interes_ordinario": "60%",
                            "interes_moratorio": "$30",
    
                        } 
                    }
                },
                ],
            },
            {
                id : "G2",
                nombre : "Grupo lobo",
                promotor: "Virginia",
                fecha : "23 de Marzo de 2021",
                estado: "",
                "integrantes": [  
                    {
                        "id": "i1",
                        "nombre" : "Ernesto Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "pendiente",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i2",
                        "nombre" : "Silvia Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "pendiente",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i3",
                        "nombre" : "Jose Trejo",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "pendiente",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i4",
                        "nombre" : "Monserrat Pacheco",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "pendiente",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i5",
                        "nombre" : "Maria Jose Anzures",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "pendiente",
                        "solicitud":{
                    
                        }
                    },
                ],
            },
            {
                id : "G3",
                nombre : "Grupo mega",
                promotor: "Maribel",
                fecha : "23 de Marzo de 2021",
                estado: "",
                "integrantes": [  
                    {
                        "id": "i1",
                        "nombre" : "Joel Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i2",
                        "nombre" : "Perla Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i3",
                        "nombre" : "Alexander Trejo",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i4",
                        "nombre" : "Virginia Pacheco",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i5",
                        "nombre" : "Maria Jose Anzures",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                ]
            },
            {
                id : "G4",
                nombre : "Fundadores de espinal tlahuanapa",
                promotor: "Arturo",
                fecha: "23 de Marzo de 2021",
                estado: "",
                "integrantes": [  
                    {
                        "id": "i1",
                        "nombre" : "Pedro Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i2",
                        "nombre" : "Andrés Cobos",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "aprobado",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i3",
                        "nombre" : "Josue Trejo",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "lista negra",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i4",
                        "nombre" : "Virginia Pacheco",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "lista negra",
                        "solicitud":{
                    
                        }
                    },
                    {
                        "id": "i5",
                        "nombre" : "Maria Jose Anzures",
                        "actividad economica" : "Comerciante",
                        "clave lector": "ASKMDSKDMF34D",
                        "telefono": "5549209235",
                        "estado": "lista negra",
                        "solicitud":{
                    
                        }
                    },
                ],
            },
        ],
        paginaSeleccionada:{
            actual: 'Solicitudes Grupales',
            destino: 'Solicitudes Personales',
            urlDestino: '/SolicitudesPersonales'
        },
        grupoSeleccionado:{
        },
        clienteSeleccionado: {
        },
        busqueda: "",
        filtro: "grupos"
    }

    handlePaginaSeleccionada(pagina){
        if(pagina === "Solicitudes Personales"){
            this.setState((state) => ({
                paginaSeleccionada: {
                    actual: 'Solicitudes Personales',
                    destino: 'Solicitudes Grupales',
                    urlDestino: '/SolicitudesGrupales'
                }
              }));
        }else if (pagina === "Solicitudes Grupales"){
            this.setState((state) => ({
                paginaSeleccionada: {
                    actual: 'Solicitudes Grupales',
                    destino: 'Solicitudes Personales',
                    urlDestino: '/SolicitudesPersonales'
                }
              }));
        }
    }

    handleGrupoSeleccionado(grupo){
        this.setState((state) => ({
            grupoSeleccionado: grupo
          }));
    }

    handleClienteSeleccionado(cliente){
        this.setState((state) => ({
            clienteSeleccionado: cliente
          }));
    }

    handleSearch = e =>{
        this.setState((state) => ({
            busqueda: e.target.value
          }));
    }

    handleFiltroSeleccionado(seleccion){
        this.setState((state) => ({
            filtro: seleccion.toLocaleLowerCase()
          }));
    }

    filtrarData(data){
        var dataFiltrada= [];

        data.forEach(element => {
            if(this.verificarEstadoGrupo(element.integrantes)){
                element.estado= "completo"
            }else{
                element.estado= "incompleto"
            }

            if(this.state.filtro === "grupos"){
                if(element.nombre.toLowerCase().includes(this.state.busqueda.toLowerCase()) || element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase()) )
                dataFiltrada.push(element);
            }else if (this.state.filtro === "clientes"){
                if(this.buscarIntegranteGrupo(element) || element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase()))  
                    dataFiltrada.push(element)
            }else if(this.state.filtro === "completo"){
                if((element.nombre.toLowerCase().includes(this.state.busqueda.toLowerCase()) || element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase())) && element.estado === 'completo' )
                    dataFiltrada.push(element);
            }else if(this.state.filtro === "incompleto"){
                if((element.nombre.toLowerCase().includes(this.state.busqueda.toLowerCase()) || element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase())) && element.estado === 'incompleto' )
                dataFiltrada.push(element);
            } 
        });

        return dataFiltrada;
    }


    verificarEstadoGrupo(integrantes){
        var counter=0, estado=false;

        for(var i=0; i<integrantes.length;i++){
            if(integrantes[i].estado !== 'aprobado') {
                counter++;
            }
        }

        if(counter>0){
            estado=false;
        }else{
            estado=true;
        }

        return estado;
    }

    buscarIntegranteGrupo(grupo){
        var integranteEncontrado= false;
        grupo.integrantes.forEach(cliente => {
            if(cliente.nombre.toLowerCase().includes(this.state.busqueda.toLowerCase())){
                integranteEncontrado=true;
            }
        });
        return integranteEncontrado;
    }

    render(){

        return(
            <Layout onPageLoad={this.handlePaginaSeleccionada} pagina={this.state.paginaSeleccionada} >
                <LayoutPage pagina={this.state.paginaSeleccionada} cliente={this.state.clienteSeleccionado} onSearch={this.handleSearch} filtro={this.state.filtro} setFiltro={this.handleFiltroSeleccionado}>
                <SolicitudGrupalList onClick={this.handleClienteSeleccionado}  solicitudes={this.filtrarData(this.state.dataInicial)} estadoGrupo={this.setEstadoGrupo}/>
                </LayoutPage>
            </Layout>
        )
    }
}

export default SolicitudesGrupales;