import React from "react";

import SolicitudGrupalListItem from "./solicitudGrupalListItem";

import '../sass/solicitudGrupalList.scss';

class SolicitudGrupalList extends React.Component {
    render() {
        return (
            <React.Fragment>
            {this.props.solicitudes.map((grupos) => <SolicitudGrupalListItem onClick={this.props.onClick} key={grupos.nombre} grupo={grupos}/>)}
            </React.Fragment>
        )
    }
}

export default SolicitudGrupalList;