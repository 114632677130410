import React from "react";
import firebase from "firebase/app";
import 'firebase/firestore' ;

export const useGetDataProspecto = (id) => {

  const [prospecto, setProspecto] = React.useState([]);
  const [domicilio, setDomicilio] = React.useState([]);
  const [avalReferenced, setAvalReferenced] = React.useState([null]);
  const [avalCliente, setAvalCliente] = React.useState([null]);
  const [domicilioAval, setDomicilioAval] = React.useState([null]); 
  const [estado, setEstado] = React.useState(['Cargando']);

  const getProspecto = async (id) =>{

    const db = firebase.firestore();
  
    db.collection("Solicitudes").doc(id).get().then((doc) => {
      if (doc.exists) {
          //console.log("Document data:", doc.data());
          setProspecto(doc.data())
          getDomicilio(doc.data().domicilioReference.id, 'Prospecto')

          //aval
          if(doc.data().aval){
            if(doc.data().aval.clienteReference){
              getAvalReferenced(doc.data().aval.clienteReference.id);
            }else if (doc.data().aval.id_Cliente){
              setAvalCliente(doc.data().aval.id_Cliente)
            }else{
              setEstado('Listo');
            }
          }else{
            setEstado('Listo')
          }

      } else {
          // doc.data() will be undefined in this case
          console.log("No se encuentra el prospecto!" + id);
          setEstado('Error')
      }
  })
  
  }

  const getDomicilio = async (id, tipoDomicilio) =>{

    const db = firebase.firestore();
  
    db.collection("Domicilios").doc(id).get().then((doc) => {
      if (doc.exists) {
          //console.log("Document data:", doc.data());
          if(tipoDomicilio === 'Prospecto'){
            setDomicilio(doc.data());
          }else if(tipoDomicilio === 'Aval'){
            setDomicilioAval(doc.data());
          }
      } else {
          // doc.data() will be undefined in this case
          console.log("No se encuentra el prospecto!" + id);
          setEstado('Error')
      }
  })
  
  }

  const getAvalReferenced = async (id) =>{

    const db = firebase.firestore();
  
    db.collection("Solicitudes").doc(id).get().then((doc) => {
      if (doc.exists) {
         // console.log("avalReference data:", doc.data());
          setAvalReferenced(doc.data())
          getDomicilio(doc.data().domicilioReference.id, 'Aval')
          setEstado('Listo')
      } else {
          // doc.data() will be undefined in this case
          console.log("No se encuentra el prospecto!" + id);
          setEstado('Error')
      }
  })
  
  }
  
  React.useEffect(() => {
    getProspecto(id)
  }, []);

  return [prospecto, avalReferenced, avalCliente, domicilio, domicilioAval, estado];
};