import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDataProspecto } from '../hooks/useGetDataProspecto';

import logotipo from "../resources/logo_light.svg";
import croquis from "../resources/croquis_icon.svg";

import '../sass/solicitud.scss';


function formatearFechaTexto(fecha, incluirDia){

    var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");

        
        return (incluirDia ? diasSemana[fecha.getDay()] + ", ": '' ) + fecha.getDate() + " de " + meses[fecha.getMonth()] 
        + " de " + fecha.getFullYear();
}

function formatearMoneda(num){
    return num.toLocaleString('en-IN', {style: 'currency',currency: 'USD', minimumFractionDigits: 2})
}

function calcularTiempoTranscurrido(fecha){
    var nacimiento = fecha.toDate();
    var hoy = new Date()

    var tiempoPasado= hoy - nacimiento
    var segs = 1000;
    var mins = segs * 60;
    var hours = mins * 60;
    var days = hours * 24;
    var months = days * 30.416666666666668;
    var years = months * 12;

    //calculo 
    var anos = Math.floor(tiempoPasado / years);

    tiempoPasado = tiempoPasado - (anos * years);
    var meses = Math.floor(tiempoPasado / months)

    tiempoPasado = tiempoPasado - (meses * months);
    var dias = Math.floor(tiempoPasado / days)

    tiempoPasado = tiempoPasado - (dias * days);
    var horas = Math.floor(tiempoPasado / hours)

    tiempoPasado = tiempoPasado - (horas * hours);
    var minutos = Math.floor(tiempoPasado / mins)

    tiempoPasado = tiempoPasado - (minutos * mins);
    var segundos = Math.floor(tiempoPasado / segs)

    return `${anos} años`;
}


function ObtenerNombreCompleto(cliente){
    return cliente.nombres + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
}

function ObtenerDireccionCompleta(domicilio){

    if(domicilio !== null && domicilio !== undefined){
    var calle = domicilio.calle;
    var num_ext = '', num_int = '';
    var colonia = domicilio.colonia;
    var municipio = domicilio.municipio;
    var estado = domicilio.estado;
    var cod_postal = domicilio.cod_postal;

    if(domicilio.num_ext == null){
        num_ext = '';
    }else{
        if(domicilio.num_ext !== 'S/N' && domicilio.num_ext !== 'NA' && domicilio.num_ext !== 'SN'){
            num_ext = '#'+ domicilio.num_ext + ' EXT.';
        }
        else{
            num_ext = 'S/N';
        }
    }

    if(domicilio.num_int == null){
        num_int = '';
    }else{
        if(domicilio.num_int !== 'S/N' && domicilio.num_int !== 'NA' && domicilio.num_int !== 'SN'){
            num_int = ' #'+ domicilio.num_int + ' INT. ';
        }
        
    }

    if(domicilio.num_ext === 'S/N' && domicilio.num_int === 'S/N'){
        num_ext = 'S/N';
        num_int = '';
    }
    
    if(domicilio.num_ext === 'S/N' && domicilio.num_int !== 'S/N'){
        num_ext ='';
    }
    


    return `C. ${calle} ${num_ext}${num_int}, COL. ${colonia}, ${municipio}, ${estado}, C.P. ${cod_postal}`;
    }
}

function ObtenerAval(prospecto, avalReferenced, avalCliente){
    // console.log("prospecto obtener aval:", prospecto);
 
     if(prospecto.aval.clienteReference){
        // console.log("avalReference obtener aval:", avalReferenced);
        return avalReferenced;
     }else if(prospecto.aval.id_Cliente){
         //console.log("cliente obtener aval:", avalCliente);
         return avalCliente;
     }else{
         return prospecto.aval;
     }
 }

 function ObtenerDomicilioAval(prospecto, avalReferenced, domicilioAval, avalCliente){
    if(ObtenerAval(prospecto, avalReferenced, avalCliente) !== null){
        if(prospecto.aval.clienteReference){
            console.log("domicilio refeenciado",domicilioAval);
           return domicilioAval;
        }else if(prospecto.aval.id_Cliente){
            return 'direccion de un cliente';
        }else if(prospecto.aval.domicilio !== null){
            return prospecto.aval.domicilio;
        }
    }
}

function ObtenerConyuge(conyuge, estado){
    return (
                <div className="conyuge-section">
                        <div className="campo nombre">
                        <p className="campo-titulo">Nombre completo:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerNombreCompleto(conyuge) : '...'}</strong></p>
                    </div>
                    <div className="campo tel">
                        <p className="campo-titulo">Teléfono:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? conyuge.telefono: '...'}</strong></p>
                    </div>
                    <div className="campo fecha-nac">
                        <p className="campo-titulo">Fecha de nacimiento:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? formatearFechaTexto(conyuge.fechaNacimiento.toDate()) : '...'}</strong></p>
                    </div>
                    <div className="campo edad">
                        <p className="campo-titulo">Edad:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? calcularTiempoTranscurrido(conyuge.fechaNacimiento) : '...'}</strong></p>
                    </div>
                    <div className="campo ocupacion">
                        <p className="campo-titulo">Ocupación:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? conyuge.ocupacion : '...'}</strong></p>
                    </div>
                    <div className="campo lugar-trabajo">
                        <p className="campo-titulo">Lugar de trabajo:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? conyuge.lugarTrabajo : '...'}</strong></p>
                    </div>
                </div>
    );
}

function ObtenerEmpresa(empresa, estado){
    return (
                <div className="empresa-section">
                    <div className="campo nombre">
                        <p className="campo-titulo">Empresa:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? empresa.nombre: '...'}</strong></p>
                    </div>
                    <div className="campo puesto">
                        <p className="campo-titulo">Puesto:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? empresa.puesto: '...'}</strong></p>
                    </div>
                    <div className="campo salario">
                        <p className="campo-titulo">Salario actual:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? formatearMoneda(empresa.salarioActual): '...'}</strong></p>
                    </div>
                    <div className="campo fecha-inicio">
                        <p className="campo-titulo">Fecha de inicio en la empresa:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? formatearFechaTexto(empresa.tiempoLaborando.toDate()): '...'}</strong></p>
                    </div>
                    <div className="campo antiguedad">
                        <p className="campo-titulo">Antigüedad:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? calcularTiempoTranscurrido(empresa.tiempoLaborando) : '...'}</strong></p>
                    </div>
                    <div className="campo tel">
                        <p className="campo-titulo">Teléfono de la empresa:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ?  empresa.telefono: '...'}</strong></p>
                    </div>
                    <div className="campo jefe">
                        <p className="campo-titulo">Jefe inmediato:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? empresa.jefeInmediato.nombre : '...'}</strong></p>
                    </div>
                    <div className="campo jefe-tel">
                        <p className="campo-titulo">Teléfono del jefe inmediato</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? empresa.jefeInmediato.telefono : '...'}</strong></p>
                    </div>
                </div>
    );
}

function ObtenerAvalInfo(aval, domicilio, estado){
    return(

        <div className="aval-section">
        <div className="campo nombre">
            <p className="campo-titulo">Nombre completo:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerNombreCompleto(aval) : '...'}</strong></p>
        </div>
        <div className="campo actividad">
            <p className="campo-titulo">Actividad económica:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? aval.actividad_economica : '...'}</strong></p>
        </div>
        <div className="campo antiguedad">
            <p className="campo-titulo">Antigüedad:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? calcularTiempoTranscurrido(aval.fecha_inicio_actividad): '...'}</strong></p>
        </div>
        <div className="campo tel">
            <p className="campo-titulo">Télefono:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? aval.telefono: '...'}</strong></p>
        </div>
        <div className="campo curp">
            <p className="campo-titulo">CURP:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? aval.curp: '...'}</strong></p>
        </div>
        <div className="campo ine">
            <p className="campo-titulo">Clave electoral INE:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? aval.clave_lector: '...'}</strong></p>
        </div>
        <div className="campo direccion">
            <p className="campo-titulo">Dirección:</p>
            <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerDireccionCompleta(domicilio): '...'}</strong></p>
        </div>
    </div>
   

    );
}

const Solicitud = (props) =>{

    const [prospecto, avalReferenced, avalCliente, domicilio, domicilioAval, estado] = useGetDataProspecto(useParams().id);
    console.log("Document data:", estado);
    console.log("domicilio",domicilio)
    //console.log("aval:", avalReferenced);
    //console.log("aval cliente:", avalCliente);

    return(
        <div className="solicitud">
            <div className="cabecera">
            <div className="logo-folios">
                <a title="Logotipo"> <img className="logotipo" src={logotipo}/> </a>
                <div className="folios">
                    <p className="parrafo">Número de cliente: _______________</p>
                    <p id="fechaHoy" className="parrafo">{prospecto.entrega !=null ? formatearFechaTexto(prospecto.entrega.fecha.toDate(),true) : 'PENDIENTE'}</p>
                    <p id="tipoPrestamo" className="encabezado">{estado === 'Listo' ? `${prospecto.solicitud.prestamo.tipoPrestamo == 'NUEVO' ? 'CLIENTE NUEVO': prospecto.solicitud.prestamo.tipoPrestamo}, PLAN: ${prospecto.solicitud.prestamo.nombrePlan}` : '...'}</p>
                    <p id="montoPlazo" className="encabezado">{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.monto)}/${prospecto.solicitud.prestamo.semanasPrestamo} SEM.` : '...'}</p>
                </div>
            </div>
            <h1 className="titulo">Hoja de registro</h1>
            <h2 className="encabezado subt-contrato">FINANCIERA MI FORTALEZA POR UN MEJOR FUTURO SAPI DE CV</h2>
        </div>
        <div className="marca-agua">
                <p>CONFIDENCIAL</p>
        </div>
        <div className="contenido">
            <div className="titulo-section">
                 <p>DATOS DEL PROSPECTO</p>
            </div>
            <div className="prospecto-section">
                <div className="campo nombre">
                    <p className="campo-titulo">Nombre completo:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerNombreCompleto(prospecto) : '...'}</strong></p>
                </div>
                <div className="campo actividad">
                    <p className="campo-titulo">Actividad económica:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.actividad_economica : '...'}</strong></p>
                </div>
                <div className="campo antiguedad">
                    <p className="campo-titulo">Antigüedad:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? calcularTiempoTranscurrido(prospecto.fecha_inicio_actividad ): '...'}</strong></p>
                </div>
                <div className="campo tel">
                    <p className="campo-titulo">Télefono:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.telefono: '...'}</strong></p>
                </div>
                <div className="campo ine">
                    <p className="campo-titulo">Clave electoral INE:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.clave_lector: '...'}</strong></p>
                </div>
                <div className="campo curp">
                    <p className="campo-titulo">CURP:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.curp: '...'}</strong></p>
                </div>
                <div className="campo direccion">
                    <p className="campo-titulo">Dirección:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerDireccionCompleta(domicilio): '...'}</strong></p>
                </div>
                <div className="campo correo">
                    <p className="campo-titulo">Correo electrónico:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoContacto.correo != '' ? prospecto.solicitud.infoContacto.correo : 'NO ESTABLECIDO': '...'}</strong></p>
                </div>
                <div className="campo tel-fijo">
                    <p className="campo-titulo">Télefono fijo:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoContacto.telFijo: '...'}</strong></p>
                </div>
                <div className="campo persona-recados">
                    <p className="campo-titulo">Persona en caso de recados:</p>
                    <p className="encabezado"><strong>{estado === 'Listo'? prospecto.solicitud.infoContacto.personaRecado: '...'}</strong></p>
                </div>
                <div className="campo tel-recados">
                    <p className="campo-titulo">Télefono en caso de recados:</p>
                    <p className="encabezado"><strong>{estado === 'Listo'? prospecto.solicitud.infoContacto.telRecado: '...'}</strong></p>
                </div>
                <div className="campo genero">
                    <p className="campo-titulo">Género:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.genero: '...'}</strong></p>
                </div>
                <div className="campo fecha-nac">
                    <p className="campo-titulo">Fecha de nacimiento:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? formatearFechaTexto(prospecto.solicitud.infoPersonal.fechaNacimiento.toDate(), false): '...'}</strong></p>
                </div>
                <div className="campo edad">
                    <p className="campo-titulo">Edad:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? calcularTiempoTranscurrido(prospecto.solicitud.infoPersonal.fechaNacimiento): '...'}</strong></p>
                </div>
                <div className="campo edo-civil">
                    <p className="campo-titulo">Estado civil:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.edoCivil: '...'}</strong></p>
                </div>
                <div className="campo posicion-hogar">
                    <p className="campo-titulo">Posición que ocupa en el hogar:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.posicionHogar: '...'}</strong></p>
                </div>
                <div className="campo estado">
                    <p className="campo-titulo">Estado:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.estadoNac: '...'}</strong></p>
                </div>
                <div className="campo pais">
                    <p className="campo-titulo">País:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.pais: '...'}</strong></p>
                </div>
                <div className="campo nacionalidad">
                    <p className="campo-titulo">Nacionalidad:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.infoPersonal.nacionalidad: '...'}</strong></p>
                </div>
            </div>
            <div className="titulo-section">
                 <p>DATOS DEL AVAL</p>
            </div>
            {estado === 'Listo' ? prospecto.aval ? ObtenerAvalInfo( ObtenerAval(prospecto, avalReferenced, avalCliente), ObtenerDomicilioAval(prospecto, avalReferenced, domicilioAval, avalCliente), estado): <div className="sin-conyuge-section"><p className="sin-conyuge-label">NO REQUIERE AVAL</p></div> : '...'}
            <div className="titulo-section">
                 <p>DATOS DEL CONYUGE O PERSONA CON QUIEN VIVE</p>
            </div>
            <div>
                {estado === 'Listo' ? prospecto.solicitud.conyuge ? ObtenerConyuge(prospecto.solicitud.conyuge, estado): <div className="sin-conyuge-section"><p className="sin-conyuge-label">CONYUGE/PERSONA CON QUIEN VIVE NO HA SIDO REGISTRADO</p></div> : '...'}
            </div>
            <div className="titulo-section">
                 <p>REFERENCIA FAMILIAR</p>
            </div>
            <div className="familiar-section">
                <div className="campo nombre">
                    <p className="campo-titulo">Nombre completo:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerNombreCompleto(prospecto.solicitud.refFamiliar) : '...'}</strong></p>
                </div>
                <div className="campo parentesco">
                    <p className="campo-titulo">Parentesco:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.refFamiliar.parentesco : '...'}</strong></p>
                </div>
                <div className="campo tel">
                    <p className="campo-titulo">Teléfono:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.refFamiliar.telefono : '...'}</strong></p>
                </div>
                <div className="campo direccion">
                    <p className="campo-titulo">Dirección:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerDireccionCompleta(prospecto.solicitud.refFamiliar.direccion): '...'}</strong></p>
                </div>
            </div>
                {estado === 'Listo' ? prospecto.solicitud.conyuge ? <div><br/><br/></div>: <div><br/><br/></div> : '...'}
                {estado === 'Listo' ? prospecto.aval ? '': <div><br/><br/><br/></div>: ''}

            <div className="titulo-section">
                <p>REFERENCIA CONOCIDO</p>
            </div>
            <div className="conocido-section">
                <div className="campo nombre">
                    <p className="campo-titulo">Nombre completo:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerNombreCompleto(prospecto.solicitud.refConocido) : '...'}</strong></p>
                </div>
                <div className="campo parentesco">
                    <p className="campo-titulo">Parentesco:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.refConocido.parentesco : '...'}</strong></p>
                </div>
                <div className="campo tel">
                    <p className="campo-titulo">Teléfono:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? prospecto.solicitud.refConocido.telefono : '...'}</strong></p>
                </div>
                <div className="campo direccion">
                    <p className="campo-titulo">Dirección:</p>
                    <p className="encabezado"><strong>{estado === 'Listo' ? ObtenerDireccionCompleta(prospecto.solicitud.refConocido.direccion): '...'}</strong></p>
                </div>
            </div>
            <div className="titulo-section">
                <p>EN CASO DE LABORAR EN UNA EMPRESA</p>
            </div>
            <div>
                {estado === 'Listo' ? prospecto.solicitud.empresa ? ObtenerEmpresa(prospecto.solicitud.empresa, estado): <div className="sin-conyuge-section"><p className="sin-conyuge-label">NO LABORA EN UNA EMPRESA</p></div> : '...'}
            </div>
            <div className="titulo-section">
                <p>INFORMACIÓN DEL DOMICILIO</p>
            </div>
            <div className="domicilio-section">
                <div className="campo croquis">
                    <img className="croquis-img" src={croquis}/>
                    <p className="campo-titulo leyenda">Croquis del domicilio</p>
                </div>
                <div className="calles">
                    <div className="campo">
                        <p className="campo-titulo">A) Calle atrás:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.calleAtras:'...' : '...'}</strong></p>
                    </div>
                    <div className="campo tipo-vivienda">
                        <p className="campo-titulo">B) Calle derecha:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.calleDerecha:'...' : '...'}</strong></p>
                    </div>
                    <div className="campo tipo-vivienda">
                        <p className="campo-titulo">C) Calle frente:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.calleFrente:'...' : '...'}</strong></p>
                    </div>
                    <div className="campo tipo-vivienda">
                        <p className="campo-titulo">D) Calle izquierda:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.calleIzquierda:'...' : '...'}</strong></p>
                    </div>
                </div>
                <div className="campos">
                    <div className="campo">
                        <p className="campo-titulo">Tipo de vivienda:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.tipoVivienda:'...' : '...'}</strong></p>
                    </div>
                    <div className="campo">
                        <p className="campo-titulo">Características/Referencias de la vivienda:</p>
                        <p className="encabezado"><strong>{estado === 'Listo' ? domicilio.croquis ? domicilio.croquis.referencias:'...' : '...'}</strong></p>
                    </div>
                </div>
            </div>
            <div className="titulo-section">
                <p>NOTIFICACIÓN DE AVISO DE PRIVACIDAD</p>
            </div>
            <div className="privacidad-section">
                <p className="aviso">
                HAGO CONSTAR QUE FINANCIERA MI FORTALEZA, S.A.P.I DE C.V., HIZO DE MI CONOCIMIENTO EL TRATAMIENTO 
                QUE SE LE DARA A LOS DATOS QUE LE HE PROPORCIONADO, POR LO QUE EXPRESO MI CONFORMIDAD Y AUTORIZO A 
                UTILIZAR LA INFORMACION PARA CUMPLIR CON LOS FINES QUE SE MENCIONAN EN EL AVISO DE PRIVACIDAD QUE ME 
                DIERON A CONOCER Y ME FUE ENTREGADO.
                </p>
            </div>
            <div className="titulo-section">
                <p>AUTORIZACION PARA SOLICITAR REPORTES DE CREDITO DE PERSONAS FISICAS</p>
            </div>
            <div className="autorizacion-section">
                <div className="legales">
                    <p>
                    POR ESTE CONDUCTO AUTORIZO EXPRESAMENTE A FINANCIERA MI FORTALEZA, S.A.P.I DE C.V., (EN ADELANTE FINANCIERA MI FORTALEZA) PARA QUE, POR CONDUCTO DE SUS FUNCIONARIOS FACULTADOS, LLEVE A CABO INVESTIGACIONES SOBRE COMPORTAMIENTO CREDITICIO EN LAS SOCIEDADES DE INFORMACION CREDITICIA (SIC) QUE ESTIME CONVENIENTE.
                    </p>
                    <p>
                        DECLARO QUE CONOZCO LA NATURALEZA Y ALCANCE DE LAS SIC DE LA INFORMACION CONTENIDA EN LOS REPORTES DE CREDITO Y EN LOS REPORTES DE CREDITO ESPECIALES, DE LA INFORMACION QUE SOLICITARA.
                    </p>
                    <p>
                        FINANCIERA MI FORTALEZA A LAS SIC, Y EL USO QUE HARA FINANCIERA MI FORTALEZA DE TAL INFORMACION.
                    </p>
                    <p>
                        AUTORIZO A FINANICERA MI FORTALEZA PARA QUE REALICE CONSULTAS PERIODICAS DE MI HISTORIAL CREDITICIO, CONSINTIENDO QUE ESTA AUTORIZACION SE ENCUENTRE VIGENTE POR UN PERIODO DE UN AÑO CONTANDO A PARTIR DE LA FIRMA DEL PRESENTE DOCUMENTO Y/O DURANTE TODO EL TIEMPO QUE MANTENGA UNA RELACION JURIDICA CON LA FINANCIERA MI FORTALEZA.
                    </p>
                </div>
                <br/><br/><br/><br/><br/>
                <div className="firmas">
                    <div className="apoderado">
                        <p className="parrafo"><strong>CHRISTIAN ALEXANDER LOPEZ TREJO</strong></p>
                        <p className="parrafo f4"><strong>APODERADO LEGAL</strong></p>
                    </div>
                    <div className="prospecto">
                        <p className="parrafo"><strong>{ObtenerNombreCompleto(prospecto)}</strong></p>
                        <p className="parrafo f4 firma-prospecto"><strong>NOMBRE Y FIRMA DE AUTORIZACIÓN DE LA CONSULTA</strong></p>
                    </div>
                    <div className="sello">
                        <p className="parrafo"><strong>FINANCIERA MI FORTALEZA</strong></p>
                        <p className="parrafo f4"><strong>SELLO DE LA EMPRESA</strong></p>
                    </div>
                </div>
            </div>
            <br/>
            {estado === 'Listo' ? prospecto.solicitud.empresa ? '': <div><br/><br/><br/></div>  :''}
            <strong>ESTA HOJA DE REGISTRO NO TIENE VALIDEZ SI NO CUENTA CON EL NOMBRE Y FIRMA DEL APODERADO LEGAL Y SELLO DE LA EMPRESA Mi FORTALEZA POR UN MEJOR FUTURO SAPI DE CV</strong>
            <div>
            </div>
        </div>
        
        
        <div className="bottom-space">

        </div>
    </div>
    )

}

export default Solicitud;