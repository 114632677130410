import React from "react";

import '../sass/solicitudGrupalList.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

import solicitudIcon from "../resources/Solicitud-icon.svg";


class SolicitudGrupalListItem extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
        this.state = {
            visibility: false,
        }
    }
    
    handleToggleVisibility() {
        this.setState((prevState) => {
            return {
                visibility: !prevState.visibility,
            }
        })
    }

    determinarEstadoGrupo(integrantes){

        var counter=0, estado={nombre: "", estilo: ""};

        for(var i=0; i<integrantes.length;i++){
            if(integrantes[i].estado !== 'aprobado') {
                counter++;
            }
        }

        if(counter>0){
            estado.nombre="Incompleto";
            estado.estilo='label-grupo-incompleto'

        }else{
            estado.nombre="Completo";
            estado.estilo='label-grupo-completo'
        }

        return (
            <label className={`${estado.estilo} parrafo`}>
                {estado.nombre} 
            </label>
        );
    }

    determinarSolicitudesGrupo(integrantes){

        var counter=0, solicitudesCompletas=false;

        for(var i=0; i<integrantes.length;i++){
            if(Object.entries(integrantes[i].solicitud).length === 0) {
                counter++;
            }
        }

        if(counter>0){
            solicitudesCompletas = false;
        }else{
            solicitudesCompletas = true;
        }

        return (
            solicitudesCompletas && <img className="solicitud-icon" src={solicitudIcon} alt="Logotipo Mi Fortaleza" />
        );
    }

    determinarEstadoCliente(estado) {
        switch(estado){
            case 'pendiente':
                return "cliente-pendiente";
            case 'rechazado':
                return "cliente-rechazado";
            case 'lista negra':
                return "cliente-lista-negra";
            default:
                return "cliente-aprobado";
        }
    }

    render() {
        const activeStatus = this.state.visibility ? 'active' : '';

        return (
        <div>
            <button className="grupo__btn" onClick={this.handleToggleVisibility}>
                <div className="inicial-grupo-container" >
                    <p className="inicial-icon">{this.props.grupo.nombre.trim().substring(0,1)}</p>
                </div>
                <div className="info-grupo-container">
                    <div className="info-container">
                        <p className="titulo">{
                        this.props.grupo.nombre.length>25 ?  this.props.grupo.nombre.trim().substring(0,25) + "..." :
                        this.props.grupo.nombre.trim()
                    }</p>
                        <p className="parrafo">{this.props.grupo.promotor}</p>
                    </div>
                    <div className="icons-grupo-container">
                        {this.determinarEstadoGrupo(this.props.grupo.integrantes)}
                        { this.determinarSolicitudesGrupo(this.props.grupo.integrantes)}
                    </div>
                </div>
                <div className="icon-desplegar-container">
                <span className="icon-desplegar">
                    {
                        this.state.visibility? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />
                    }
                </span>
                </div>
            </button>
                {this.props.grupo.integrantes.map((cliente)=>{
                return(
                    <p key={cliente.id} onClick={()=>this.props.onClick(cliente)} className={`accordion__content ${activeStatus} ${this.determinarEstadoCliente(cliente.estado)}`}>
                        {
                        cliente.nombre
                        }
                    </p>
                )
                })}
        </div>
        );
    }
}

export default SolicitudGrupalListItem;

