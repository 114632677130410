import React from 'react';

import NavBar from './navbarMultiple';

class Layout extends React.Component{
    render(){   
        return(
            <React.Fragment>
                <NavBar 
                    paginas = {this.props.paginas}
                    paginaSeleccionada={this.props.paginaSeleccionada}
                    onPageSelected= {this.props.onPageSelected}
                    accion="Salir" 
                    urlAccion="/login"/>
                {this.props.children}
            </React.Fragment>
    
        )
    }
}

export default Layout;