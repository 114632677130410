import React from 'react';

import '../sass/inputfield.scss';

class Inputfield extends React.Component {

    render() {

    const {
        campo,
          } = this.props;

      return (
        <div className="inputfield-wrapper">
            <label className="input-label">{campo}</label>
            <input className="searchbar" name={campo} value={this.props.valor} type="text"></input>
        </div>
      );
    }
  }

  export default Inputfield;