import React from "react";

import '../sass/multipleBtn.scss'


class MultipleBtn extends React.Component {
  
  render() {

    const {
      imgSrc,
      opciones,
        } = this.props;

    return (
      <div className="multiple-btn">
        <button className="dropbtn">
          <img src={imgSrc} alt="Botón con imagen" />
        </button>
        <div className="multiple-btn-content">
        {opciones.map((opcion)=>{     
            return(
              opcion === 'WhatsApp' ?
              <a key={opcion} href={`https://api.whatsapp.com/send?phone=52${this.props.objects}`} rel="noopener noreferrer" target="_blank">{opcion}</a>
              : <a key={opcion} href="/">{opcion}</a>
            )
        })}
        </div>
      </div> 
    );
  }
}

export default MultipleBtn;
