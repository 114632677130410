import React from "react";

import '../sass/dropdown.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


class Dropdown extends React.Component {
  
  render() {

    const {
      opciones,
      filtroSeleccionado
        } = this.props;

    function titleCase(str) {
      return str.toLowerCase().split(' ').map(function(word) {
        return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
    }

    return (
      <div className="dropdown">
        <button className="dropbtn">{titleCase(filtroSeleccionado)}
        <FontAwesomeIcon className="icon-drop" icon={faCaretDown} />
        </button>
        <div className="dropdown-content">
        {opciones.map((opcion)=>{     
            return(
              filtroSeleccionado !== opcion.toLowerCase() &&  
                <button key={opcion} onClick={()=>this.props.setFiltro(opcion)}>{opcion}</button>
            )
        })}
        </div>
      </div> 
    );
  }
}

export default Dropdown;
