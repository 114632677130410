import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDataProspecto } from '../hooks/useGetDataProspecto';

import logotipo from "../resources/logo_light.svg";
import '../sass/contrato.scss';

function ObtenerNombreCompleto(cliente){
    return cliente.nombres + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
}

function formatearFecha(fecha){

    var result;

    var fechaEntrega = fecha.toDate();

    let day = fechaEntrega.getDate()
    let month = fechaEntrega.getMonth() + 1
    let year = fechaEntrega.getFullYear()

    if(month < 10 || day < 10){
     result = `${day}/0${month}/${year}`;
        if(day < 10){
        result = `0${day}/0${month}/${year}`;
        }else{
        result = `${day}/${month}/${year}`;
        }
    }else{
    result = `${day}/${month}/${year}`;
    }

    return result;
}

function formatearFechaTexto(fecha){

    var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");

        
        return diasSemana[fecha.getDay()] + ", " + fecha.getDate() + " de " + meses[fecha.getMonth()] 
        + " de " + fecha.getFullYear();
}

function formatearMoneda(num){
    return num.toLocaleString('en-IN', {style: 'currency',currency: 'USD', minimumFractionDigits: 2})
}

function formatearPorcentaje(num){
    return num.toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
}

function ObtenerDireccionCompleta(domicilio){

    const calle = domicilio.calle;
    var num_ext = '', num_int = '';
    var colonia = domicilio.colonia;
    var municipio = domicilio.municipio;
    var estado = domicilio.estado;
    var cod_postal = domicilio.cod_postal;

    if(domicilio.num_ext == null){
        num_ext = '';
    }else{
        if(domicilio.num_ext !== 'S/N' && domicilio.num_ext !== 'NA' && domicilio.num_ext !== 'SN'){
            num_ext = '#'+ domicilio.num_ext + ' EXT.';
        }
        else{
            num_ext = 'S/N';
        }
    }

    if(domicilio.num_int == null){
        num_int = '';
    }else{
        if(domicilio.num_int !== 'S/N' && domicilio.num_int !== 'NA' && domicilio.num_int !== 'SN'){
            num_int = ' #'+ domicilio.num_int + ' INT. ';
        }
        
    }

    if(domicilio.num_ext === 'S/N' && domicilio.num_int === 'S/N'){
        num_ext = 'S/N';
        num_int = '';
    }
    
    if(domicilio.num_ext === 'S/N' && domicilio.num_int !== 'S/N'){
        num_ext ='';
    }
    

    return `C. ${calle} ${num_ext}${num_int}, COL. ${colonia}, ${municipio}, ${estado}, C.P. ${cod_postal}`;
}

function setDiaEntrega(fecha){
    const dias = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ];
      
      var fechaEntrega = fecha.toDate();
      const numeroDia = fechaEntrega.getDay();
      const nombreDia = dias[numeroDia];

      return nombreDia;
}

function getPrimerPago(prestamo){
    return prestamo.reqPrimerPago ? `${formatearMoneda(prestamo.primerPago)}` : 'NO REQUIERE'
}

const Contrato = (props) =>{

    const [prospecto, avalReferenced, avalCliente, domicilio, domicilioAval, estado] = useGetDataProspecto(useParams().id);
    console.log("Document data:", estado);

    return(
    <div className="contrato">
        <div className="cabecera">
            <div className="logo-folios">
                <a title="Logotipo"> <img className="logotipo" src={logotipo}/> </a>
                <div className="folios">
                    <p className="parrafo">Número de cliente: _______________</p>
                    <p id="fechaHoy" className="parrafo">{prospecto.entrega !=null ? formatearFechaTexto(prospecto.entrega.fecha.toDate()) : 'PENDIENTE'}</p>
                    <p id="tipoPrestamo" className="encabezado">{estado === 'Listo' ? `${prospecto.solicitud.prestamo.tipoPrestamo == 'NUEVO' ? 'CLIENTE NUEVO': prospecto.solicitud.prestamo.tipoPrestamo}/${prospecto.solicitud.prestamo.nombrePlan}` : '...'}</p>
                </div>
            </div>
            <h1 className="titulo">Contrato de Préstamo Personal</h1>
            <h2 className="encabezado subt-contrato">FINANCIERA MI FORTALEZA POR UN MEJOR FUTURO SAPI DE CV</h2>
        </div>
        <div className="cliente">
            <p id="nombreCliente" className="parrafo">NOMBRE DEL CLIENTE: <strong>{ObtenerNombreCompleto(prospecto)}</strong></p>
            <p id="direccionCliente" className="parrafo">DIRECCIÓN DEL CLIENTE: <strong>{ObtenerDireccionCompleta(domicilio)}</strong></p>
        </div>
        <div className="prestamo">
            <div className="subt-info">
                <a className="encabezado">Información del préstamo</a>
            </div>
            <div className="cat">
                <div>
                <a className="parrafo">CAT PROMEDIO</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearPorcentaje(prospecto.solicitud.prestamo.cat/100)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="monto-prestamo">
                <div>
                <a className="parrafo">MONTO DEL PRÉSTAMO</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.monto)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="cargo-servicio">
                <div>
                <a className="parrafo">CARGO POR SERVICIO</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.cargoServicio)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="total-pagar">
                <div>
                <a className="parrafo">TOTAL A PAGAR</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.totalPagar)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="primer-abono">
                <div>
                <a className="parrafo">MONTO DEL PRIMER ABONO</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? getPrimerPago(prospecto.solicitud.prestamo) : '...'}</strong></a>
                </div>
            </div>
            <div className="abonos-subsecuentes">
                <div>
                <a className="parrafo">MONTO DE LOS ABONOS SUBSECUENTES</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.pagoSemanal)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="dia-pago">
                <div>
                <a className="parrafo">¿QUÉ DÍA SE REALIZAN LOS PAGOS?</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${setDiaEntrega(prospecto.entrega.fecha)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="plazo">
                <div>
                <a className="parrafo">PLAZO DEL PRESTAMO</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${prospecto.solicitud.prestamo.semanasPrestamo} Semanas` : '...'}</strong></a>
                </div>
            </div>
            <div className="interes-moratorio">
                <div>
                <a className="parrafo">INTERES MORATORIO SEMANAL</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.interesMoratorio)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="interes-anual">
                <div>
                <a className="parrafo">INTERES ORDINARIO ANUAL</a>
                <a className="parrafo"><strong>{estado === 'Listo' ? `Plan: ${prospecto.solicitud.prestamo.semanasPrestamo} Semanas, ${formatearPorcentaje(prospecto.solicitud.prestamo.interesOrdinario/100)}` : '...'}</strong></a>
                </div>
            </div>
            <div className="promotor">
                <div>
                <a className="parrafo">NOMBRE DEL PROMOTOR</a>
                <a className="parrafo" href=''><strong>{prospecto.promotor}</strong></a>
                </div>
            </div>
        </div>
        <div className="legal">
            <p className="parrafo p-contrato">
                Los pagos se deberán realizar de forma semanal el día estipulado en la 
                caratula de este contrato, en caso del incumplimiento del mismo, la 
                Financiera Mi Fortaleza por un Mejor Futuro SAPI de CV tiene todo el derecho 
                de requerir el monto total de la cuenta para evitar gastos de cobranza 
                innecesarios. 
            </p>
            <p className="parrafo p-contrato">
                Interés Ordinario: Este tipo de interés es aquel que el titular deberá de 
                pagar por el financiamiento del préstamo otorgado, el cual es calculado de 
                forma anual.
            </p>
            <p className="parrafo p-contrato">
                Interés Moratorio: A diferencia del interés ordinario, este tipo de interés de
                nombre moratorio solo aplica para efectos de que el titular no cumpla con sus 
                pagos en tiempo y forma a partir de la fecha de suscripción del contrato y deberá
                 pagar el 1% de dicho interés semanalmente.
            </p>
            <p className="parrafo p-contrato">
                Contrato de préstamo que celebra por una parte Mi Fortaleza por un Mejor Futuro 
                S.A.P.I de C.V. y por la otra parte cuyo nombre aparece en la parte superior de 
                este contrato del cliente en adelante el "Cliente" al tenor de las declaraciones y 
                clausulas siguientes:
            </p>
            <ol className="clausulas">
                <li>Declara Mi Fortaleza por un Mejor Futuro SAPI de CV por conducto de su 
                    apoderado legal que:
                    <br/>
                    <br/><br/>
                    <br/><br/><br/>
                    <ol>
                    <br/>
                    <br/><br/>
                        <li>
                            Su representada es una sociedad mercantil constituida conforme a 
                            las leyes de los Estados Unidos Mexicanos, inscrita en el registro
                             público de la propiedad y del comercio y cuenta con las facultades
                              suficientes para celebrar este contrato, las cuales no le han sido
                               revocadas, modificadas ni limitadas en forma alguna.
                        </li>
                        <li>
                            El principal objeto social de su representada, consiste en otorgar
                             préstamos a personas físicas residentes en México, entre otras
                              actividades.
                        </li>
                        <li>
                            Su representada está debidamente registrada ante el servicio de 
                            administración tributaria (SAT) bajo el Registro Federal de 
                            Contribuyentes Nº FFU200821P63, teniendo su domicilio fiscal en 
                            Av. Mariano Arista #309, Col. Tajín, Poza Rica de Hgo. Ver. 
                            México C.P. 93330
                        </li>
                    </ol>
                </li>
                <li>Declara el cliente por su propio derecho y bajo protesta de decir verdad:
                    <ol>
                        <li>
                            Es una persona física, de nacionalidad mexicana y que cuenta con 
                            la capacidad necesaria para la celebración y cumplimiento del 
                            presente contrato.
                        </li>
                        <li>
                            Que toda   la documentación proporcionada a Mi Fortaleza por un
                             Mejor Futuro SAPI de CV, así como   la información que asentó en
                              la hoja de registro es cierta y verídica.
                        </li>
                        <li>
                            Que cuenta con la capacidad económica suficiente para cumplir con 
                            las obligaciones de pago derivadas de este contrato.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
        <div className="firmas">
            <div className="top-firma">
                <div className="firma-container">
                    <p className="parrafo"><strong>{ObtenerNombreCompleto(prospecto)}</strong></p>
                    <p className="parrafo f1"><strong>NOMBRE Y FIRMA DEL SOLICITANTE</strong></p>
                </div>
                <div className="firma-container">
                    <p className="parrafo"><strong>{prospecto.promotor}</strong></p>
                    <p className="parrafo f2"><strong>NOMBRE Y FIRMA DEL PROMOTOR</strong></p>
                </div>
            </div>
            <div className="mid-firma">
                <div  className="firma-container">
                    <p className="parrafo"><strong>PAPANTLA, VER.</strong></p>
                    <p className="parrafo f3"><strong>LUGAR DE EXPEDICIÓN DEL CONTRATO</strong></p>
                </div>
            </div>
            <div className="bottom-firma">
                <div>
                    <p className="parrafo"><strong>CHRISTIAN ALEXANDER LOPEZ TREJO</strong></p>
                    <p className="parrafo f4"><strong>APODERADO LEGAL</strong></p>
                </div>
                <p className="parrafo f5"><strong>SELLO DE LA EMPRESA</strong></p>
            </div>
        </div>
        <div className="pie-pagina">
            <p className="parrafo"> ESTA HOJA DE CONTRATO DE CREDITO NO TIENE VALIDEZ SI NO CUENTA CON EL NOMBRE Y FIRMA DEL APODERADO LEGAL Y SELLO 
                DE LA EMPRESA Mi FORTALEZA POR UN MEJOR FUTURO SAPI DE CV.
            </p>
        </div>
    </div>


    );

}


export default Contrato;