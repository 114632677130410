import React from 'react';

import SolicitudesList from '../components/SolicitudesList';

import '../sass/solicitudesPersonales.scss'
import Layout from '../components/layout';
import LayoutPage from '../components/layoutPage';


class SolicitudesPersonales extends React.Component{

    constructor(props) {
        super(props)
        this.handleClienteSeleccionado = this.handleClienteSeleccionado.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleFiltroSeleccionado = this.handleFiltroSeleccionado.bind(this)
        this.handlePaginaSeleccionada = this.handlePaginaSeleccionada.bind(this)
        this.handleAvalSeleccionado = this.handleAvalSeleccionado.bind(this)
        this.handleInformacionSeleccionado = this.handleInformacionSeleccionado.bind(this)
    }

    state = {
        dataInicial: [
            {}
        ],
        paginaSeleccionada:{
            actual: 'Solicitudes Personales',
            destino: 'Solicitudes Grupales',
            urlDestino: '/SolicitudesGrupales'
        },
        clienteSeleccionado: { },
        contieneAval: false,
        avalSeleccionado: false,
        busqueda: "",
        filtro: "todos"
    }

    componentDidMount(){
    }

    handlePaginaSeleccionada(pagina){
        if(pagina === "Solicitudes Personales"){
            this.setState((state) => ({
                paginaSeleccionada: {
                    actual: 'Solicitudes Personales',
                    destino: 'Solicitudes Grupales',
                    urlDestino: '/SolicitudesGrupales'
                }
              }));
        }else if (pagina === "Solicitudes Grupales"){
            this.setState((state) => ({
                paginaSeleccionada: {
                    actual: 'Solicitudes Grupales',
                    destino: 'Solicitudes Personales',
                    urlDestino: '/SolicitudesPersonales'
                }
              }));
        }
    }

    handleClienteSeleccionado(cliente, aval){
        
        if(cliente.data.aval != null){
            this.setState((state) => ({
                clienteSeleccionado: cliente.data,
                avalSeleccionado: false,
                contieneAval: true,
              }));
        }else{
            this.setState((state) => ({
                clienteSeleccionado: cliente.data,
                avalSeleccionado: false,
                contieneAval: false,
              }));
        }
    }

    handleInformacionSeleccionado(){
        this.setState((state) => ({
            avalSeleccionado: false
        }));
    }

    handleAvalSeleccionado(){
        Object.entries(this.state.clienteSeleccionado).length !== 0 &&
        this.setState((state) => ({
            avalSeleccionado: true
        }));
    }

    handleSearch = e =>{
        this.setState((state) => ({
            busqueda: e.target.value
          }));
    }

    handleFiltroSeleccionado(seleccion){
        this.setState((state) => ({
            filtro: seleccion.toLocaleLowerCase()
          }));
    }

    filtrarData(data){
        var dataFiltrada= [];
        
        data.forEach(element => {
            
            if(this.state.filtro === "todos"){
                if(element.nombres.toLowerCase().includes(this.state.busqueda.toLowerCase()) || element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase()) )
                dataFiltrada.push(element);
            }else{
                if((element.promotor.toLowerCase().includes(this.state.busqueda.toLowerCase()) || element.nombre.toLowerCase().includes(this.state.busqueda.toLowerCase())) & element.estado.toLowerCase() === this.state.filtro )
                dataFiltrada.push(element);
            }

        });

        return dataFiltrada;
    }

    render(){

        return(
            <Layout onPageLoad={this.handlePaginaSeleccionada} pagina={this.state.paginaSeleccionada}>
                <LayoutPage pagina={this.state.paginaSeleccionada} 
                cliente={this.state.clienteSeleccionado} 
                onInformacionSeleccionado={this.handleInformacionSeleccionado}
                contieneAval={this.state.contieneAval}
                aval={this.state.avalSeleccionado}
                onAvalSeleccionado={this.handleAvalSeleccionado} 
                onSearch={this.handleSearch}  filtro={this.state.filtro} 
                setFiltro={this.handleFiltroSeleccionado}>
                    <SolicitudesList onClick={this.handleClienteSeleccionado}/>
                </LayoutPage>
            </Layout>
           )
    }
}

export default SolicitudesPersonales;