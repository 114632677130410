import React from "react";
import {Link} from "react-router-dom";

import '../sass/navbar.scss'
import logotipo from "../resources/logo_light.svg";
import DropDownMenu from "./dropdownMenu";

class NavBar extends React.Component {
  
  render() {

    const {
      pagina,
      accion,
      urlAccion
    } = this.props;

    return (
      <div className="top-bar-container">
        <img className="logotipo-topbar" src={logotipo} alt="Logotipo Mi Fortaleza" />
        <DropDownMenu onPageLoad={this.props.onPageLoad} pagina={pagina}/>
        <nav className="top-desktop-nav">
          <ul>
            <li className="divider">
              <Link to={urlAccion}>{accion}</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default NavBar;
