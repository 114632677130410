import React from "react";
import {Link} from "react-router-dom";

import '../sass/dropdownmenu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


class dropdownMenuMultiple extends React.Component {
  
  render() {

    const {
      paginas,
      paginaSeleccionada,
        } = this.props;

    return (
      <div className="dropdown-menu">
        <button className="dropbtn">{paginaSeleccionada}
        <FontAwesomeIcon className="icon-drop" icon={faCaretDown} />
        </button>
        <div className="dropdown-content">
        {   
        
        paginas.map((pag)=>{
          return(

              <a onClick={() => this.props.onPageSelected(pag)}>{pag}</a>

          )})
        }
        </div>
      </div> 
    );
  }
}

export default dropdownMenuMultiple;
