import React from 'react';

import '../sass/pagare.scss';

const FirmaAval = (props) =>{
    return (

        <div className="aval">
            <div className="cabecera">
                <h1 className="subtitulo">AVAL</h1>
            </div>
            <p className="parrafo">Nombre completo: {'  '}<strong>{props.nombre}</strong></p>
            <p className="parrafo">Dirección:  {'  '}<strong>{props.direccion}</strong></p>
            <p className="parrafo fpagare2">Firma del aval:</p>
        </div>

    )
}

export default FirmaAval;