import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDataProspecto } from '../hooks/useGetDataProspecto';

import FirmaAval from '../components/firmaAval';

import logotipo from "../resources/logo_light.svg";
import '../sass/pagare.scss';

function formatearFechaTexto(fecha){

    var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");

        
        return diasSemana[fecha.getDay()] + " " + fecha.getDate() + " de " + meses[fecha.getMonth()] 
        + " de " + fecha.getFullYear();
}

function ObtenerNombreCompleto(cliente){
    return cliente.nombres + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
}

function ObtenerDireccionCompleta(domicilio){

    if(domicilio !== null && domicilio !== undefined){
    var calle = domicilio.calle;
    var num_ext = '', num_int = '';
    var colonia = domicilio.colonia;
    var municipio = domicilio.municipio;
    var estado = domicilio.estado;
    var cod_postal = domicilio.cod_postal;

    if(domicilio.num_ext == null){
        num_ext = '';
    }else{
        if(domicilio.num_ext !== 'S/N' && domicilio.num_ext !== 'NA' && domicilio.num_ext !== 'SN'){
            num_ext = '#'+ domicilio.num_ext + ' EXT.';
        }else{
            num_ext = 'S/N';
        }
    }

    if(domicilio.num_int == null){
        num_int = '';
    }else{
        if(domicilio.num_int !== 'S/N' && domicilio.num_int !== 'NA' && domicilio.num_int !== 'SN'){
            num_int = ' #'+ domicilio.num_int + ' INT. ';
        }
        
    }

    if(domicilio.num_ext === 'S/N' && domicilio.num_int === 'S/N'){
        num_ext = 'S/N';
        num_int = '';
    }
    
    if(domicilio.num_ext === 'S/N' && domicilio.num_int !== 'S/N'){
        num_ext ='';
    }
    

    return `C. ${calle} ${num_ext}${num_int}, COL. ${colonia}, ${municipio}, ${estado}, C.P. ${cod_postal}`;
    }
}

function ObtenerAval(prospecto, avalReferenced, avalCliente){
   // console.log("prospecto obtener aval:", prospecto);

    if(prospecto.aval.clienteReference){
       // console.log("avalReference obtener aval:", avalReferenced);
       return avalReferenced;
    }else if(prospecto.aval.id_Cliente){
        //console.log("cliente obtener aval:", avalCliente);
        return avalCliente;
    }else{
        return prospecto.aval;
    }
}

function ObtenerDomicilioAval(prospecto, avalReferenced, domicilioAval, avalCliente){
    if(ObtenerAval(prospecto, avalReferenced, avalCliente) !== null){
        if(prospecto.aval.clienteReference){
            console.log("domicilio refeenciado",domicilioAval);
           return domicilioAval;
        }else if(prospecto.aval.id_Cliente){
            return 'direccion de un cliente';
        }else if(prospecto.aval.domicilio !== null){
            return prospecto.aval.domicilio;
        }
    }
}

function GenerarFirmaAval(aval, domicilio){
    console.log("aval de la firma", aval)
    console.log("domicilio de la firma", domicilio)

    return <FirmaAval nombre={ObtenerNombreCompleto(aval)} 
        direccion={ObtenerDireccionCompleta(domicilio)} /> 
}

function formatearMoneda(num){
    return num.toLocaleString('en-IN', {style: 'currency',currency: 'USD', minimumFractionDigits: 2})
}

const Pagare = (props) =>{

    const [prospecto, avalReferenced, avalCliente, domicilio, domicilioAval, estado] = useGetDataProspecto(useParams().id);
    console.log("Estado:", estado);
    //console.log("aval:", avalReferenced);
    //console.log("aval cliente:", avalCliente);
    console.log("domicilio aval:", domicilioAval);

    return(
        <div className="pagare">
        <div className="cabecera-pagare">       
                <a title="Logotipo" > <img className="logotipo" src={logotipo}/> </a>
                <h1 className="titulo">PAGARÉ</h1>
        </div>
        <div className="contenido">
            <p className="parrafo">
                Por este pagaré me (nos) obligo (amos) a pagar incondicionalmente a la orden 
                de Financiera Mi Fortaleza por un Mejor Futuro S.A.P.I de C.V., la cantidad de {'  '}
                 <strong> {estado === 'Listo' ? `${formatearMoneda(prospecto.solicitud.prestamo.totalPagar)}` : '...'}</strong>{'  '} la cantidad en letra, {'  '} 
                 <strong>{estado === 'Listo' ? ` (${prospecto.solicitud.prestamo.totalPagarLetra}) ` : '...'}</strong>{'  '}
                en sus oficinas ubicadas en Mariano Arista No. 309 Colonia Tajín, 
                Código Postal 93330, Poza Rica de Hidalgo Veracruz o en cualquier Ciudad o 
                lugar en que se requiera el día y forma que se precisan en el quinto párrafo 
                de este título de crédito.
            </p>
            <p className="parrafo">VALOR RECIBIDO A MI ENTERA SATISFACCION EN EFECTIVO.</p>
            <p className="parrafo">
                El importe de este pagaré causara intereses ordinarios a razón del {'  '}
                <strong>{estado === 'Listo' ? `${prospecto.solicitud.prestamo.interesOrdinario}% anual` : '...'}</strong>{'  '}
                a partir de la fecha de suscripción y hasta el día de su vencimiento.
            </p>
            <p className="parrafo">
                La cantidad antes señalada será pagada en {'  '}
                <strong>{estado === 'Listo' ? `${prospecto.solicitud.prestamo.semanasPrestamo}` : '...'} amortizaciones semanales</strong>{'  '}
                y consecutivas, precisamente en las fechas y por los montos establecidos 
                en la caratula del contrato.
            </p>
            <p className="parrafo">
                En caso de que por circunstancias extraordinarias las Instituciones de 
                Crédito señaladas se negarán a recibir el pago de las fechas correspondientes,
                 el suscriptor se obliga a realizar el pago en el domicilio ubicado en: {'  '}
                 <strong>
                    5 de mayo No. 601-A, Colonia Centro, Código Postal 93400, 
                    Papantla de Olarte, Veracruz.
                 </strong>
            </p>
            <p className="parrafo">
                El suscriptor reconoce y acepta que la falta de pago oportuno de una o más 
                de las amortizaciones pactadas que conforme a este pagaré debe realizar, 
                generará el vencimiento anticipado de los pagos que falten por efectuar, 
                siendo exigible por Financiera mi Fortaleza S.A.P.I de C.V el pago inmediato 
                de todas las amortizaciones no pagadas más intereses moratorios a razón de 
                una tasa del <strong>108%</strong> anual calculado diariamente sobre el capital devengado 
                y no pagado conforme a la caratula del contrato, desde el día siguiente 
                en que incurrió en mora y hasta que efectúe el pago total del adeudo.
            </p>
            <p className="parrafo">
                Lugar y fecha de suscripción: {'  '}
                <strong>
                5 de mayo No. 601-A, Colonia Centro, Código Postal 93400, 
                Papantla de Olarte, Veracruz, a {estado === 'Listo' ? `${formatearFechaTexto(prospecto.entrega.fecha.toDate())}` : '...'}.
                </strong>
            </p>
        </div>
        <div className="cliente">
            <div className="cabecera">
                <h1 className="subtitulo">ACEPTO</h1>
                <h1 className="subtitulo">EL SUSCRIPTOR</h1>
            </div>
            <br/>
            <p className="parrafo">NOMBRE COMPLETO: {'  '}<strong>{estado === 'Listo' ? `${ObtenerNombreCompleto(prospecto)}` : '...'}</strong></p>
            <p className="parrafo">DIRECCIÓN: {'  '}<strong>{estado === 'Listo' ? `${ObtenerDireccionCompleta(domicilio)}` : '...'}</strong></p>
            <p className="parrafo fpagare1">Firma del deudor:</p>
        </div>
        {
          estado === 'Listo' ? prospecto.reqAval ? GenerarFirmaAval(ObtenerAval(prospecto, avalReferenced, avalCliente), ObtenerDomicilioAval(prospecto, avalReferenced, domicilioAval, avalCliente)):' ':''

        }
        <div className="bottom-space">

        </div>
    </div>
    )

}

export default Pagare;