import React from 'react';

import '../sass/docPrestamoPersonal.scss'
import Layout from '../components/layoutDocumentos';
import Contrato from '../components/contrato';
import Pagare from '../components/pagare';
import Solicitud from '../components/solicitud';

class DocPrestamoPersonal extends React.Component{

    constructor(props) {
        super(props)
        this.handlePaginaSeleccionada = this.handlePaginaSeleccionada.bind(this)
    }

    state = {
        paginaSeleccionada: 'Contrato de crédito',
        paginas: ['Contrato de crédito','Pagaré del cliente','Solicitud de crédito']
    }

    handlePaginaSeleccionada(pagina){
        this.setState((state) => ({
            paginaSeleccionada: pagina
        }));
    }

    handlePageRender(){
        switch(this.state.paginaSeleccionada){
            case 'Contrato de crédito':
                 return <Contrato/>
                 break;
            case 'Pagaré del cliente':
                return <Pagare/>
                break;
            case 'Solicitud de crédito':
                return <Solicitud/>
                break;
            default:
                return <Contrato/>
        }
    }

    render(){

        return(
            <Layout onPageSelected={this.handlePaginaSeleccionada} paginas= {this.state.paginas} paginaSeleccionada={this.state.paginaSeleccionada}>
                {
                    this.handlePageRender()
                }
            </Layout>
           )
    }
}

export default DocPrestamoPersonal;