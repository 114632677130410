import React from "react";
import {Link} from "react-router-dom";

import '../sass/dropdownmenu.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


class DropDownMenu extends React.Component {
  
  render() {

    const {
      pagina,
        } = this.props;

    return (
      <div className="dropdown-menu">
        <button className="dropbtn">{pagina.actual}
        <FontAwesomeIcon className="icon-drop" icon={faCaretDown} />
        </button>
        <div className="dropdown-content">
          <Link to={pagina.urlDestino} onClick={() => this.props.onPageLoad(pagina.destino)}>{pagina.destino}</Link>
        </div>
      </div> 
    );
  }
}

export default DropDownMenu;
