import React from 'react';
import {Link} from "react-router-dom";
import { useGetData } from "../hooks/useGetData";



import BtnImg from './btnimg';

import '../sass/solicitudesList.scss';
import solicitudIcon from "../resources/Solicitud-icon.svg";

function DeterminarLabel(estado){

    var result;

    switch(estado){
        case 'lista negra':
            result = "label-estado-cliente-lista-negra";
            break;
        case 'aval':
            result = "label-estado-cliente-aval";
            break;
        case 'rechazado':
            result = "label-estado-cliente-rechazado";
            break;
        case 'validar':
            result = "label-estado-cliente-validar";
            break;
        case 'entrega':
            result = "label-estado-cliente-aprobado";
            break;
        case 'cliente':
            result = "label-estado-cliente-aprobado";
            break;
        case 'visitar':
            result = "label-estado-visitar-cliente";
            break;
        default:
            result = ' ';
         break;
    }

    return result;

}

function DeterminarLabelStr(estado){

    var result;

    switch(estado){
        case 'lista negra':
            result = "Lista Negra";
            break;
        case 'aval':
            result = "Requiere Aval";
            break;
        case 'rechazado':
            result = "Rechazado";
            break;
        case 'validar':
            result = "Validar domicilio";
            break;
        case 'entrega':
            result = "Entrega: ";
            break;  
        case 'cliente':
            result = "Préstamo entregado";
            break;
        case 'visitar':
            result = "Visitar cliente";
            break;
        default:
            result = ' ';
            break;
    }

    return result;

}

function formatearFecha(fecha){

    var result;
    var isToday;

    var fechaEntrega = fecha.toDate();

    let date = new Date();



    let day = fechaEntrega.getDate()
    let month = fechaEntrega.getMonth() + 1
    let year = fechaEntrega.getFullYear()

    if(day === date.getDate() && month === fechaEntrega.getMonth() + 1 && year === fechaEntrega.getFullYear()){
        isToday = true;
    }else{
        isToday = false;
    }

    if(month < 10 || day < 10){
     result = `${day}/0${month}/${year}`;
        if(day < 10){
        result = `0${day}/0${month}/${year}`;
        }else{
        result = `${day}/${month}/${year}`;
        }
    }else{
    result = `${day}/${month}/${year}`;
    }

    if (isToday){
        result = 'Hoy';
    } 

    return result;
}

function ObtenerInfoEntrega(entrega,estado){

    if(estado !== 'cliente'){
        return entrega != null ?  formatearFecha(entrega.fecha) + ', ' + entrega.hora : ''; 
    }else{
        return ''
    }
}

function ObtenerNombreCompleto(cliente){
    return cliente.nombres + ' ' + cliente.apellido_paterno + ' ' + cliente.apellido_materno;
}

const SolicitudesList = (props) =>{
    const [documents] = useGetData();
        return(
            <ul className="card-list">
                {
                documents.map((cliente)=>{
                return(
                <li key={cliente.id} onClick={()=>props.onClick(cliente)}>
                    <div className="inicial-icon-container" >
                        <p className="inicial-icon" >{cliente.data.nombres.trim().substring(0,1)}</p>
                    </div>
                    <div className="info-cliente-container">
                        <div className="info-cliente">
                            <p className="titulo">{ObtenerNombreCompleto(cliente.data).length>25 ? ObtenerNombreCompleto(cliente.data).trim().substring(0,25) + "...": ObtenerNombreCompleto(cliente.data).trim()}</p>
                            <p className="parrafo">
                                {`Act: ${cliente.data.actividad_economica.length>10 ? 
                                    cliente.data.actividad_economica.trim().substring(0,10) + "...": 
                                    cliente.data.actividad_economica} 
                                    / Prom: ${cliente.data.promotor.length>20 ? 
                                    cliente.data.promotor.trim().substring(0,20) + "...": 
                                    cliente.data.promotor}`}
                            </p>
                        </div>
                        <p className={`parrafo ${DeterminarLabel(cliente.data.estado)}`}>{DeterminarLabelStr(cliente.data.estado) + ObtenerInfoEntrega(cliente.data.entrega, cliente.data.estado) }</p>
                    </div>
                    <div className="solicitud-icon-container">
                        { cliente.data.solicitud != null && cliente.data.entrega != null &&
                        <Link to={`/Documentos/${cliente.id}`}>
                            <BtnImg imgSrc={solicitudIcon} addedStyle="solicitud-icon" />
                        </Link>
                        }
                    </div>
                </li>
                )
                })}
            </ul>
        )
}

export default SolicitudesList;