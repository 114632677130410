import React from 'react';

import Inputfield from '../components/inputfield';
import logotipo from "../resources/logo_light.svg";

import '../sass/login.scss'


class Login extends React.Component{

    render(){

        return(
            <div className="login-container">
                <div className="logo-login">
                    <img className="img-login" src={logotipo} alt="Logotipo Mi Fortaleza" />
                    <h1 className="titulo">GESTOR DE SOLICITUDES DE CRÉDITO</h1>
                </div>
                <div className="login-form">
                    <h1 className="titulo">INICIAR SESIÓN</h1>
                    <Inputfield campo="USUARIO"/>
                    <Inputfield campo="CONTRASEÑA"/>
                    <button class="btn-entrar" type="button"><span>ENTRAR</span></button>
                </div>
            </div>
        )
    }
}

export default Login;